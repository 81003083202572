import { useEffect, useMemo } from "react";
import { useContextApi } from "../../contexts/context";
import Pusher from "pusher-js";
import { toast } from "react-toastify";
import { APROVALS } from "../../contexts/actionTypes";
const Index = () => {
  const { user, dispatch, approvals_count } = useContextApi();
  const pusher = useMemo(
    () =>
      new Pusher(process.env.REACT_APP_PUSHER_KEY, {
        cluster: process.env.REACT_APP_PUSHER_CLUSTER,
        encrypted: true
      }),
    []
  );

  const appointmentChannel = pusher.subscribe(
    "appointment-channel." + user.default_office
  );
  const paymentChannel = pusher.subscribe(
    "payment-channel." + user.default_office
  );

  useEffect(() => {
    appointmentChannel.bind("AppointmentEvent", function (data) {
      toast.info(data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    });

    return () => {
      pusher.unsubscribe("appointment-channel." + user.default_office);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentChannel]);

  useEffect(() => {
    paymentChannel.bind("PaymentEvent", function (data) {
      toast.info(data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      dispatch({ type: APROVALS, payload: approvals_count + 1});
    });

    return () => {
      pusher.unsubscribe("payment-channel." + user.default_office);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentChannel]);
};
export default Index;
